import React from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage/HomePage';
import BlogPage from './components/BlogPage/BlogPage';
import PortfolioPage from './components/PortfolioPage/PortfolioPage';
import LoginPage from './components/Auth/LoginPage';
import AdminPage from './components/Auth/AdminPage';

function PrivateRoute({ children }) {
  return localStorage.getItem('isAuthenticated') === 'true' ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
