import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const AdminContainer = styled.div`
  margin-top: 40px; /* Adjusted for the navbar height */
  padding: 20px;
`;

const Form = styled.form`
  margin-bottom: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
`;

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #282c34;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #61dafb;
  }
`;

const Hr = styled.hr`
  margin: 40px 0;
`;

function AdminPage() {
  const [blogTitle, setBlogTitle] = useState('');
  const [blogContent, setBlogContent] = useState('');
  const [websiteName, setWebsiteName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [overlayComments, setOverlayComments] = useState('');
  const [tags, setTags] = useState('');

  const [blogPosts, setBlogPosts] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  // Retrieve the logged-in user's email from localStorage
  const loggedInUser = localStorage.getItem('userEmail');

  useEffect(() => {
    fetch('https://rjfbago.pt:3001/api/blogPosts')
      .then(response => response.json())
      .then(data => setBlogPosts(data))
      .catch(error => console.error('Error fetching blog posts:', error));

    fetch('https://rjfbago.pt:3001/api/portfolioItems')
      .then(response => response.json())
      .then(data => setWebsites(data))
      .catch(error => console.error('Error fetching portfolio items:', error));
  }, []);

  const handleAddBlogPost = (e) => {
    e.preventDefault();
    const newBlogPost = {
      id: Date.now(),
      title: blogTitle,
      content: blogContent,
      createdAt: new Date(),
      lastEditedAt: new Date(),
      user: loggedInUser, // Add the logged-in user's email
    };

    fetch('https://rjfbago.pt:3001/api/blogPosts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newBlogPost),
    })
      .then(response => response.json())
      .then(data => {
        setBlogPosts([...blogPosts, data]);
        setBlogTitle('');
        setBlogContent('');
      })
      .catch(error => console.error('Error adding blog post:', error));
  };

  const handleAddWebsite = (e) => {
    e.preventDefault();
    const newTags = tags.split(',').map(tag => tag.trim());
    const newWebsite = {
      id: Date.now(),
      name: websiteName,
      url: websiteUrl,
      overlayComments,
      tags: newTags,
      createdAt: new Date(),
      lastEditedAt: new Date(),
      user: loggedInUser, // Add the logged-in user's email
    };

    fetch('https://rjfbago.pt:3001/api/portfolioItems', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newWebsite),
    })
      .then(response => response.json())
      .then(data => {
        setWebsites([...websites, data]);
        setWebsiteName('');
        setWebsiteUrl('');
        setOverlayComments('');
        setTags('');
      })
      .catch(error => console.error('Error adding website:', error));
  };

  const handleEditSelection = (e) => {
    const [type, index] = e.target.value.split('-');
    setSelectedItem({ type, index: parseInt(index, 10) });

    if (type === 'blog') {
      const selectedBlog = blogPosts[parseInt(index, 10)];
      setBlogTitle(selectedBlog.title);
      setBlogContent(selectedBlog.content);
    } else if (type === 'website') {
      const selectedWebsite = websites[parseInt(index, 10)];
      setWebsiteName(selectedWebsite.name);
      setWebsiteUrl(selectedWebsite.url);
      setOverlayComments(selectedWebsite.overlayComments);
      setTags(selectedWebsite.tags.join(', '));
    }
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const { type, index } = selectedItem;
    const newTags = tags.split(',').map(tag => tag.trim());

    if (type === 'blog') {
      const updatedBlogPosts = [...blogPosts];
      updatedBlogPosts[index] = {
        ...updatedBlogPosts[index],
        title: blogTitle,
        content: blogContent,
        lastEditedAt: new Date(),
        user: loggedInUser, // Add the logged-in user's email for edits
      };

      fetch(`https://rjfbago.pt:3001/api/blogPosts/${updatedBlogPosts[index].id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedBlogPosts[index]),
      })
        .then(() => {
          setBlogPosts(updatedBlogPosts);
          setSelectedItem(null);
          setBlogTitle('');
          setBlogContent('');
        })
        .catch(error => console.error('Error editing blog post:', error));
    } else if (type === 'website') {
      const updatedWebsites = [...websites];
      updatedWebsites[index] = {
        ...updatedWebsites[index],
        name: websiteName,
        url: websiteUrl,
        overlayComments,
        tags: newTags,
        lastEditedAt: new Date(),
        user: loggedInUser, // Add the logged-in user's email for edits
      };

      fetch(`https://rjfbago.pt:3001/api/portfolioItems/${updatedWebsites[index].id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedWebsites[index]),
      })
        .then(() => {
          setWebsites(updatedWebsites);
          setSelectedItem(null);
          setWebsiteName('');
          setWebsiteUrl('');
          setOverlayComments('');
          setTags('');
        })
        .catch(error => console.error('Error editing website:', error));
    }
  };

  const handleDeleteSelection = () => {
    if (!selectedItem) return;

    const { type, index } = selectedItem;
    const id = type === 'blog' ? blogPosts[index].id : websites[index].id;

    fetch(`https://rjfbago.pt:3001/api/${type === 'blog' ? 'blogPosts' : 'portfolioItems'}/${id}`, {
      method: 'DELETE',
    })
      .then(() => {
        if (type === 'blog') {
          const updatedBlogPosts = blogPosts.filter((_, i) => i !== index);
          setBlogPosts(updatedBlogPosts);
        } else if (type === 'website') {
          const updatedWebsites = websites.filter((_, i) => i !== index);
          setWebsites(updatedWebsites);
        }
        setSelectedItem(null); // Clear the selection after deletion
      })
      .catch(error => console.error('Error deleting item:', error));
  };

  return (
    <AdminContainer>
      <h2>Add a New Blog Post</h2>
      <Form onSubmit={handleAddBlogPost}>
        <Input
          type="text"
          placeholder="Blog Title"
          value={blogTitle}
          onChange={(e) => setBlogTitle(e.target.value)}
        />
        <TextArea
          placeholder="Blog Content"
          value={blogContent}
          onChange={(e) => setBlogContent(e.target.value)}
        />
        <Button type="submit">Add Blog Post</Button>
      </Form>

      <h2>Add a New Website</h2>
      <Form onSubmit={handleAddWebsite}>
        <Input
          type="text"
          placeholder="Website Name"
          value={websiteName}
          onChange={(e) => setWebsiteName(e.target.value)}
        />
        <Input
          type="url"
          placeholder="Website URL"
          value={websiteUrl}
          onChange={(e) => setWebsiteUrl(e.target.value)}
        />
        <TextArea
          placeholder="Overlay Comments"
          value={overlayComments}
          onChange={(e) => setOverlayComments(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Tags (comma separated)"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
        />
        <Button type="submit">Add Website</Button>
      </Form>

      <Hr />

      <h2>Edit or Delete Existing Content</h2>
      <Form onSubmit={handleEditSubmit}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <select onChange={handleEditSelection} style={{ flex: 1 }}>
            <option value="">Select an item to edit or delete</option>
            {blogPosts.map((post, index) => (
              <option key={index} value={`blog-${index}`}>{`Blog Post: ${post.title}`}</option>
            ))}
            {websites.map((site, index) => (
              <option key={index} value={`website-${index}`}>{`Website: ${site.name}`}</option>
            ))}
          </select>
          <Button onClick={handleDeleteSelection} type="button">Delete</Button>
        </div>

        {selectedItem && selectedItem.type === 'blog' && (
          <>
            <Input
              type="text"
              placeholder="Blog Title"
              value={blogTitle}
              onChange={(e) => setBlogTitle(e.target.value)}
            />
            <TextArea
              placeholder="Blog Content"
              value={blogContent}
              onChange={(e) => setBlogContent(e.target.value)}
            />
          </>
        )}

        {selectedItem && selectedItem.type === 'website' && (
          <>
            <Input
              type="text"
              placeholder="Website Name"
              value={websiteName}
              onChange={(e) => setWebsiteName(e.target.value)}
            />
            <Input
              type="url"
              placeholder="Website URL"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
            />
            <TextArea
              placeholder="Overlay Comments"
              value={overlayComments}
              onChange={(e) => setOverlayComments(e.target.value)}
            />
            <Input
              type="text"
              placeholder="Tags (comma separated)"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
            />
          </>
        )}

        <Button type="submit" disabled={!selectedItem}>
          Save Changes
        </Button>
      </Form>
    </AdminContainer>
  );
}

export default AdminPage;
