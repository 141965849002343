import React, { Suspense, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF, useAnimations } from '@react-three/drei';


const CVLinks = styled.div`
  margin-top: 20px;
`;

const CVLink = styled.a`
  display: inline-block;
  margin-right: 20px;
  padding: 10px 20px;
  background-color: #282c34;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #61dafb;
  }
`;

const HomeContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  padding-top: 60px; /* Adjusted for the navbar height */
`;

const TextSection = styled.div`
  flex: 7;
  padding: 40px;
  box-sizing: border-box; /* Ensure padding is included in the width */
  background-color: #f8f8f8; /* Light background for contrast */
  overflow-y: auto; /* Scroll if content overflows vertically */
`;

const ModelSection = styled.div`
  flex: 3;
  background-color: #e0e0e0; /* Placeholder background color */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensures the model is contained within the section */
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  pointer-events: none;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.5s ease;
  z-index:1;
`;

function Model() {
  const { scene, animations } = useGLTF('/xander_model_character_man_rigged_realisitc.glb');  //https://sketchfab.com/3d-models/xander-model-character-man-rigged-realisitc-ae935d7b05b943b3809c72a781cb074c
  const { actions } = useAnimations(animations, scene);

  useEffect(() => {
    if (actions && animations.length > 0) {
      actions[animations[0].name].play();
    }
  }, [actions, animations]);

  return <primitive object={scene} scale={1.3} />;
}

function HomePage() {
  const [overlayVisible, setOverlayVisible] = useState(true);

  const handleMouseEnter = () => {
    setOverlayVisible(false); // Hide overlay on first hover
  };

  return (
    <HomeContainer>
      <TextSection>
        <h1>Welcome to My Portfolio</h1>
        <p>
          This is where you can introduce yourself, talk about your experiences, skills, and what makes you unique. Feel free to expand on your journey, your passions, and any interesting projects you've worked on.
          This section is flexible, so you can add more details, images, or even links to your work. The goal here is to make a lasting impression on visitors and give them a reason to explore the rest of your portfolio.
        </p>
        <CVLinks>
          <CVLink href="/CV2023 - RicardoBago - PT.pdf" target="_blank">My CV (Portuguese)</CVLink>
          <CVLink href="/CV2024-RicardoBago - EN.pdf" target="_blank">My CV (English)</CVLink>
        </CVLinks>
      </TextSection>
      <ModelSection onMouseEnter={handleMouseEnter}>
        <Overlay visible={overlayVisible}>3D model, spin me</Overlay>
        <Canvas camera={{ position: [0, 1, 3], fov: 50 }}>
          <Suspense fallback={null}>
            <OrbitControls target={[0, 1.2, 0]} maxPolarAngle={Math.PI / 2} />
            <ambientLight intensity={0.5} />
            <directionalLight position={[5, 5, 5]} />
            <Model />
          </Suspense>
        </Canvas>
      </ModelSection>
    </HomeContainer>
  );
}

export default HomePage;
