import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';
import { FiChevronDown, FiMenu } from 'react-icons/fi';

const NavbarContainer = styled.nav`
  width: 100%;
  height: 60px;
  background-color: #282c34;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: ${(props) => (props.open ? 'flex' : 'none')};
    flex-direction: column; /* Stack items vertically */
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #282c34;
    padding: 20px;
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 0 15px;

  &:hover {
    color: #61dafb;
  }

  @media (max-width: 768px) {
    margin: 10px 0; /* Vertical spacing between menu items */
  }
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 100px; /* Adjusted margin to give space between social icons and the language dropdown */
  position: relative;
`;

const SocialIconLink = styled.a`
  color: white;
  font-size: 1.5rem;
  margin-left: 15px; /* Adjusted to ensure proper spacing */
  
  &:hover {
    color: #61dafb;
  }
`;

const HiddenLink = styled(Link)`
  color: black;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  position: relative;
`;

const LanguageButton = styled.div`
  position: absolute;
  right: 0; /* Ensure it's on the far right with no gap */
  top: 0;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #3b3f44; /* Different background color */
  padding: 0 15px;
  cursor: pointer;
  border-left: 1px solid #61dafb; /* Optional: to differentiate it from the rest of the navbar */
`;

const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 60px; /* Aligns the dropdown content just below the navbar */
  right: 0;
  background-color: #282c34;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 12px;
  border-radius: 0px; /* Removed rounded borders */
  z-index: 1;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #61dafb;
  }
`;

const MenuButton = styled.div`
  display: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const languageFlags = {
    pt: { src: 'https://flagcdn.com/16x12/pt.png', label: 'Português' },
    en: { src: 'https://flagcdn.com/16x12/us.png', label: 'English' },
    es: { src: 'https://flagcdn.com/16x12/es.png', label: 'Español' },
    fr: { src: 'https://flagcdn.com/16x12/fr.png', label: 'Français' },
    de: { src: 'https://flagcdn.com/16x12/de.png', label: 'Deutsch' }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <NavbarContainer>
      <MenuButton onClick={toggleMenu}>
        <FiMenu />
      </MenuButton>
      <NavLinks open={menuOpen}>
        <NavLink to="/">My Profile</NavLink>
        <NavLink to="/blog">Blog</NavLink>
        <NavLink to="/portfolio">Portfolio</NavLink>
      </NavLinks>
      <SocialIcons>
        <SocialIconLink href="https://www.linkedin.com/in/ricardojfbago/" target="_blank">
          <FaLinkedin />
        </SocialIconLink>
        <SocialIconLink href="https://github.com/Bak0" target="_blank">
          <FaGithub />
        </SocialIconLink>
        <HiddenLink to="/login">.</HiddenLink> {/* Ensure this is correctly positioned */}
        <SocialIconLink href="mailto:rbago@conquer-it.pt">
          <FaEnvelope />
        </SocialIconLink>
      </SocialIcons>
      <LanguageButton onClick={toggleDropdown}>
        <FlagIcon src={languageFlags.en.src} alt={languageFlags.en.label} />
        <FiChevronDown />
        <DropdownContent isOpen={dropdownOpen}>
          {Object.keys(languageFlags).map((key) => (
            <DropdownItem key={key}>
              <FlagIcon src={languageFlags[key].src} alt={languageFlags[key].label} />
              {languageFlags[key].label}
            </DropdownItem>
          ))}
        </DropdownContent>
      </LanguageButton>
    </NavbarContainer>
  );
};

export default Navbar;
