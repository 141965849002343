import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BlogPost from './BlogPost';

const BlogContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 80px; /* Adjusted for the navbar height */

  @media (max-width: 768px) {
    padding: 10px;
    padding-top: 60px;
  }
`;

function BlogPage() {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    fetch('https://rjfbago.pt:3001/api/blogPosts')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched blog posts:', data);
        setBlogPosts(data.reverse()); // Reverse the array to show newest posts first
      })
      .catch(error => console.error('Error fetching blog posts:', error));
  }, []);

  return (
    <BlogContainer>
      {blogPosts.map((post, index) => (
        <BlogPost key={index} post={post} />
      ))}
    </BlogContainer>
  );
}

export default BlogPage;
