import React from 'react';
import styled from 'styled-components';

const PostContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 15px;
    margin-bottom: 15px;
  }
`;

const PostTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const PostContent = styled.p`
  margin: 10px 0 0;
  font-size: 1rem;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Timestamp = styled.div`
  margin-top: 10px;
  font-size: 0.8rem;
  color: gray;
  text-align: right;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const EditedTimestamp = styled.div`
  margin-top: 5px;
  font-size: 0.7rem;
  color: gray;
  text-align: right;

  @media (max-width: 768px) {
    font-size: 0.6rem;
  }
`;

function BlogPost({ post }) {
  return (
    <PostContainer>
      <PostTitle>{post.title}</PostTitle>
      <PostContent>{post.content}</PostContent>
      <Timestamp>Created: {new Date(post.createdAt).toLocaleString()}</Timestamp>
      {post.lastEditedAt && post.lastEditedAt !== post.createdAt && (
        <EditedTimestamp>Last Edited: {new Date(post.lastEditedAt).toLocaleString()}</EditedTimestamp>
      )}
    </PostContainer>
  );
}

export default BlogPost;
